import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import { Link } from "gatsby";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Home Loan Programs | Right Start | Mortgage Lender"
        description="Find the home loan that's right for you with Right Start. There are programs designed to fit all types of buyers. Connect with a loan officer to get started."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-primary-50 pb-10 pt-16 md:pb-12 md:pt-20">
        <div className="mb-20 md:mb-32">
          <div className="container">
            <header className="mb-16 max-w-[640px] md:mb-20">
              <h1>Which Loan Is Right for You?</h1>
              <p>
                Finding the right home is just part of the process. First,
                you’ll need a loan and there are programs designed to fit all
                types of home buyers.
              </p>
            </header>

            <div className="grid gap-y-12 md:grid-cols-3 md:gap-x-6 lg:gap-x-10">
              <Link
                to="/fha-home-loans/"
                className="block overflow-hidden rounded-3xl bg-white px-4 py-6 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10"
              >
                <div className="mb-6">
                  <StaticImage
                    src="../images/0.0 Repeating Modules/Loan Program Cards/FHA.jpg"
                    loading="lazy"
                    width={153}
                    className="z-0 w-[96px] rounded-full md:w-[153px]"
                  />
                </div>
                <h3 className="heading-four mb-3">FHA</h3>
                <p className="mb-0">
                  FHA loans are perfect for first-time home buyers—they offer
                  low down payments and have reasonable income and credit
                  expectations.
                </p>
              </Link>

              <Link
                to="/va-home-loans/"
                className="block overflow-hidden rounded-3xl bg-white px-4 py-6 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10"
              >
                <div className="mb-6">
                  <StaticImage
                    src="../images/0.0 Repeating Modules/Loan Program Cards/VA.jpg"
                    loading="lazy"
                    width={153}
                    className="z-0 w-[96px] rounded-full md:w-[153px]"
                  />
                </div>
                <h3 className="heading-four mb-3">VA</h3>
                <p className="mb-0">
                  VA loans offer exceptional benefits for military veterans and
                  active service members, such as low interest rates and no
                  money down.
                </p>
              </Link>

              <Link
                to="/conventional-home-loans/"
                className="block overflow-hidden rounded-3xl bg-white px-4 py-6 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10"
              >
                <div className="mb-6">
                  <StaticImage
                    src="../images/0.0 Repeating Modules/Loan Program Cards/Conventional.jpg"
                    loading="lazy"
                    width={153}
                    className="z-0 w-[96px] rounded-full md:w-[153px]"
                  />
                </div>
                <h3 className="heading-four mb-3">Conventional</h3>
                <p className="mb-0">
                  A conventional loan is a great option for those with a solid
                  credit score and minimal debt.
                </p>
              </Link>

              <Link
                to="/usda-home-loans/"
                className="block overflow-hidden rounded-3xl bg-white px-4 py-6 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10"
              >
                <div className="mb-6">
                  <StaticImage
                    src="../images/0.0 Repeating Modules/Loan Program Cards/USDA.jpg"
                    loading="lazy"
                    width={153}
                    className="z-0 w-[96px] rounded-full md:w-[153px]"
                  />
                </div>
                <h3 className="heading-four mb-3">USDA</h3>
                <p className="mb-0">
                  If your dream home is in a rural area, a USDA loan offers no
                  down payment and a minimal investment.
                </p>
              </Link>

              <Link
                to="/jumbo-home-loans/"
                className="block overflow-hidden rounded-3xl bg-white px-4 py-6 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10"
              >
                <div className="mb-6">
                  <StaticImage
                    src="../images/0.0 Repeating Modules/Loan Program Cards/Jumbo.jpg"
                    loading="lazy"
                    width={153}
                    className="z-0 w-[96px] rounded-full md:w-[153px]"
                  />
                </div>
                <h3 className="heading-four mb-3">Jumbo</h3>
                <p className="mb-0">
                  If you’re interested in higher-priced homes, you can apply for
                  a jumbo loan to finance one that exceeds conforming loan
                  limits.
                </p>
              </Link>

              <button
                data-modal-open="modal-contact"
                className="block overflow-hidden rounded-3xl bg-white px-4 py-6 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10"
              >
                <div className="mb-6">
                  <StaticImage
                    src="../images/0.0 Repeating Modules/Loan Program Cards/Need Help.jpg"
                    loading="lazy"
                    width={153}
                    className="z-0 w-[96px] rounded-full md:w-[153px]"
                  />
                </div>
                <h3 className="heading-four mb-3">Need Help?</h3>
                <p className="mb-0">
                  Our mortgage advisors are happy to review loan programs with
                  you to see which one is the best fit.
                </p>
              </button>
            </div>
          </div>
        </div>
        <CallToAction className="!mb-0" />
      </section>
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
